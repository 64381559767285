import React from "react";

export default function project3() {
  return (
    <>
      <div className="jumbotron jumbotron-fluid custom-jumbotron shadow-lg">
        <div className="container">
          <h1 className="display-5 jumbotron-text-spacing text-center">
            Data Management
          </h1>
          <p className="lead text-center pb-5">Cleaning/Consolidating Data</p>
        </div>
      </div>

      <div className="container border border-top-0 mt-3 text-black">
        <h3 className="mt-4">Cleaning Survey Data</h3>
        <p>
          From askamanager.org I downloaded real-world survey data that was
          meant to serve as a database for different jobs. Since some survey
          fields allowed users to enter data themselves, there were input
          inconsistencies that made the dataset inconvenient to use (e.g. "u.s."
          vs "United States" vs "US of America" etc). As such, my goal for this
          project was to clean the dataset by standardizing how said data was
          entered in an effort to improve usability. There were instances where
          enacting some change would affect how the dataset could be analyzed.
          In these situations I didn't make the change but rather discussed them
          and offered possible solutions so that another user/analyst could make
          the final decision and be conscious how a certain change could affect
          their analysis.
        </p>
        <div className="container text-center mb-3">
          <a
            href="https://github.com/ivanaguilar9890/cleaning-data"
            className="btn btn-primary me-2 shadow-none"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/github.svg" alt="Bootstrap" width="24" height="24"></img>{" "}
            Click to View Github Repository
          </a>
        </div>

        <h3 className="mt-4">Crypto: Data from Decentralized Exchanges</h3>
        <p>
          In my final semester at USC my machine learning class partnered with a
          small company that does math consulting for other companies. Long
          story short, the smaller company had us work in groups to develop
          machine learning models to better understand decentralized exchanges
          (a type of cryptocurrency exchange). In truth our tasks were mostly
          focused on data wrangling such as finding sources of data, weighing
          pros/cons of each source, and developing a data pipeline so that the
          consulting company could create their own ML models. The Github
          repository below contains the part of a module I wrote as well as a
          small tutorial showing how to use it.
        </p>
        <div className="container text-center mb-3">
          <a
            href="https://github.com/ivanaguilar9890/dex-module"
            className="btn btn-primary me-2 shadow-none"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/github.svg" alt="Bootstrap" width="24" height="24"></img>{" "}
            Click to View Github Repository
          </a>
        </div>
        <h3>Super Bowl History</h3>
        <p>
          In this two part mini-project I attempt to get Super Bowl data from
          various Wikipedia pages. For the first part I use an intermediary API
          to work alongside the Wikipedia API to get Super Bowl data from a
          singular page. For the second part I use the Wikipedia API directly to
          get Super Bowl data from 56 pages. By the end of the second part I was
          able to clean and consolidate all the data into a singular Microsoft
          Excel file for future use. The data cleaning for this project was
          focused on correcting text that had been "corrupted" with extra
          letters/symbols. Click on either of the two buttons below to view the
          specified Github repository (or just click on the second one to view
          the finalized project).
        </p>
        <div className="container text-center mb-4">
          <a
            href="https://github.com/ivanaguilar9890/super-bowl-history-pt1"
            className="btn btn-primary me-2 shadow-none"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/github.svg" alt="Bootstrap" width="24" height="24"></img>{" "}
            Click to View Part 1
          </a>
          <a
            href="https://github.com/ivanaguilar9890/super-bowl-history-pt2"
            className="btn btn-primary mt-4 mt-sm-0"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/github.svg" alt="Bootstrap" width="24" height="24"></img>{" "}
            Click to View Part 2
          </a>
        </div>
      </div>
    </>
  );
}
