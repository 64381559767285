import React from "react";
import { Link } from "react-router-dom";

export default function Projects() {
  document.title = "Personal Projects";

  return (
    <>
      <div className="jumbotron jumbotron-fluid custom-jumbotron shadow-lg">
        <div className="container">
          <h1 className="display-5 jumbotron-text-spacing text-center">
            Projects demonstrating skills I've learned
          </h1>
          <p className="lead text-center pb-5">
            Click on the project's image to view the project in greater detail.
          </p>
        </div>
      </div>

      <div className="container text-black">
        <div className="row h-100 mt-4 border-dark border-bottom">
          <div className="col-md-7 my-auto text-center">
            <h2>Data Management</h2>
            <p className="text-center text-md-left">
              These are small projects where I practiced cleaning and working
              with data of various forms.
            </p>
          </div>
          <div className="col-md-5">
            <Link to="/projects/project3">
              <img
                className="img-fluid p-1 mb-4 light-blue-card"
                src="github.png"
              ></img>
            </Link>
          </div>
        </div>

        <div className="row h-100 flex-md-row-reverse border-dark border-bottom mt-4">
          <div className="col-md-7 my-auto text-center">
            <h2>Analysis of Consumer Complaints</h2>
            <p className="text-center text-md-left">
              Using only Microsoft Excel, I analyzed data collected by the
              Consumer Financial Protection Bureau. In particular, I focused on
              exploring the geographic distribution of the complaints and the
              impact of COVID-19.
            </p>
          </div>
          <div className="col-md-5">
            <Link to="/projects/project1">
              <img
                className="img-fluid p-1 mb-4 light-blue-card"
                src="project_1v1.png"
              ></img>
            </Link>
          </div>
        </div>

        <div className="row h-100  mt-4">
          <div className="col-md-7 my-auto text-center">
            <h2>Crime Rate Prediction</h2>
            <p className="text-center text-md-left">
              Using Python, my goal was to create a regression model to predict
              property crime rates at the U.S. state level. To this end, I used
              government provided statistics such as the poverty and
              unemployment rates as my predictors.
            </p>
          </div>
          <div className="col-md-5">
            <Link to="/projects/project2">
              <img
                className="img-fluid p-1 mb-4 light-blue-card"
                src="pylogo.png"
              ></img>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
