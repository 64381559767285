import React from "react";

export default function About() {
  document.title = "About Page";
  return (
    <>
      <div className="jumbotron jumbotron-fluid custom-jumbotron shadow-lg">
        <div className="container">
          <h1 className="display-5 jumbotron-text-spacing text-center">
            About Me
          </h1>
          <p className="lead text-center pb-5">Short Bio</p>
        </div>
      </div>

      <div className="container pb-2 border border-top-0 border-bottom-0">
        <p className="text-black">
          Hello, my name is Ivan Aguilar - nice to meet you (or rather write to
          you). I was born and raised in Los Angeles, California and recently
          graduated from the University of Southern California. I majored in
          Applied and Computational Mathematics (B.S.) and minored in Computer
          Programming. In short, these subsets of mathematics intend to be of
          practical use to various fields such as finance or medicine. I hope
          that I can apply the skills I've learned and continue to hone in those
          fields.
        </p>
        <p className="text-black">
          From data aggregation to developing models and analyzing their
          effectiveness, I was heavily encouraged to develop a versatile skill
          set to meet the demand for practicality. So while I primarily work
          with Python and Microsoft Excel, I am more than comfortable learning
          new products/services to complete various tasks at hand. To me, data
          consolidation and rigorous analysis are vital to the success of a
          project. Without good data, analysis will suffer, and without good
          analysis, any efforts to collect data will have gone to waste. This
          unique challenge that working with data has to offer is something that
          I thoroughly enjoy solving.
        </p>
        <p className="text-black">Best,</p>
        <p className="text-black">Ivan Aguilar</p>
      </div>
    </>
  );
}
