import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Projects from "./Projects";
import Project1 from "./Project1";
import Project2 from "./Project2";
import Project3 from "./Project3";
import Home from "./Home";
import About from "./About";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

function App() {
  return (
    <BrowserRouter>
      <Navbar className="navbar-custom" variant="dark" expand="sm">
        <Container>
          <Navbar.Brand>I.Aguilar</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-item nav-link nav-link-custom" to="/">
                Home
              </Link>
              <Link
                className="nav-item nav-link nav-link-custom"
                to="/projects"
              >
                Projects
              </Link>
              <Link className="nav-item nav-link nav-link-custom" to="/about">
                About
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/project1" element={<Project1 />} />
        <Route path="/projects/project2" element={<Project2 />} />
        <Route path="/projects/project3" element={<Project3 />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
