import React from "react";
import { Accordion } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
export default function project1() {
  return (
    <>
      <div className="jumbotron jumbotron-fluid custom-jumbotron shadow-lg">
        <div className="container">
          <h1 className="display-5 jumbotron-text-spacing text-center">
            Analysis of Consumer Complaints
          </h1>
          <p className="lead text-center pb-5">
            Exploration of CFPB data with Micrsoft Excel
          </p>
        </div>
      </div>

      <div className="container mt-3 text-black border border-top-0 border-bottom-0 mb-3">
        <h3>Background/Motivation</h3>
        <p>
          Using Microsoft Excel, I analyzed 2021 data provided by the Consumer
          Financial Protection Bureau{" "}
          <a
            href="https://www.consumerfinance.gov/data-research/consumer-complaints/"
            target="_blank"
            rel="noopener noreferrer"
          >
            (CFPB)
          </a>
          . The data are composed of consumer complaints towards financial
          products or companies after being stripped of personal information. It
          is important to note that the CFPB views these complaints as
          allegations rather than facts. In short, this means that some
          complaints may not be legitimate as they can be based on incorrect
          assumptions. We should therefore be careful to take a consumer's
          narrative at face value. Nonetheless, analyzing this data is still
          worthwhile as these complaints may help companies improve customer
          relations or show issues within the financial marketplace that
          legislation may hope to solve. Having said that, I sought to analyze
          the geographic distribution of the complaints and the effect of Covid
          as I felt they were of greater interest to the general population.
        </p>
        {/* end of Motivation */}
        <Accordion defaultActiveKey={["1", "2"]} alwaysOpen flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Geographic Insights (Click to View)
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Methodology</strong>: When consumers send a complaint to
                the CFPB, they are given the option to include the state where
                they reside. Naturally, we should wonder if the geographic
                distribution of the complaints can tell us anything. Perhaps
                there is a certain region/state where complaints are
                significantly greater than in any other state. If that is the
                case, does there exist some underlying issue that could explain
                said phenomena? Or could the high incidence of complaints be
                explained by the failure of certain companies to work with
                residents of said state? To answer these questions we can begin
                by using the Map feature in Excel which allows us to display
                geographic data and then use Pivot Tables to better understand
                our results. A few things to note: due to high variability in
                state populations we must convert “complaints” into “complaints
                per 1,000” to better draw comparisons between states, and some
                complaints are sent either without a location or are from a U.S.
                territory which means we won't be using the entire data set. The
                latter point shouldn't be of huge concern as said data only
                accounts &lt; 2% of the dataset.
              </p>
              <p>
                <strong>Results/Analysis:</strong> After accounting for
                population, most complaints in the 50 states come from the
                Southern Region (Figure 1). In particular, they are from the
                South Atlantic with the West South Central Division trailing
                closely behind (Figure 2). One might guess that Florida is to
                blame, or rather, because Florida has the largest population in
                this division, most complaints would be from said state.
                Surprisingly, however, Georgia produces the most consumer
                complaints in that census division with 3.29 complaints per
                1,000 people (Figure 3). Nationally,{" "}
                <b> Georgia also ranks first in consumer complaints </b>.
                Indeed, the second-ranking state is Florida, with 2.46
                complaints per 1,000 people. This 0.83 difference between the
                top two states is interesting. Before attempting to reason why
                that may be the case, we should first determine if this is
                statistically significant thereby indicating that “something” is
                truly amiss in Georgia. By creating a box plot of all consumer
                complaint rates, we see that the{" "}
                <b>
                  consumer complaint rate of Georgia is statistically
                  significant{" "}
                </b>
                (Figure 4). In short, Georgia's consumer complaint rate of 3.29
                is outside the interquartile range of 0.30-2.74 thereby showing
                it is an outlier.
              </p>
              <div className="container justify-content-center text-center">
                <div className="row justify-content-center text-center">
                  <Carousel
                    className=" justify-content-center text-center w-100"
                    variant="dark"
                  >
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Geography-Figure1.png"
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Geography-Figure2.png"
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Geography-Figure3.png"
                        alt="Third slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className=" w-100"
                        src="/project_one_images/Geography-Figure4.png"
                        alt="Fourth slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              <p className="mt-4">
                Naturally, the existence of this outlier warrants further
                discussion. Compared to the rest of the Nation (including D.C.
                and other territories), Georgia has 6.7 percent more complaints
                against products labeled "Credit Reporting, Credit Repair
                services, or other personal consumer reports" and 3.31 percent
                more complaints towards "Debt Collection" (Figure 5). At a
                glance, this suggests that there are issues with how companies
                that work with credit (reporting, repair, collections, and so
                on) conduct business in Georgia. Perhaps human/computer error is
                at play, and it is the onus of the companies to address. On the
                other hand, we could reason that residents of Georgia are simply
                more likely to report these incidents to the CFPB. Determining
                the correctness of the latter assertion will be difficult with
                the limited information we have. In other words, we would need
                to quantify how much "reach" the CFPB has in Georgia compared to
                other locations. For example, I previously never knew about the
                CFPB, but perhaps more people from my age group in Georgia might
                know about it and be more likely to contact it. We can, however,
                get a better assessment of the first assertion by reviewing the
                sub-products named in each complaint. To this end, we compare
                the sub-product distributions of Georgia and rest of the nation.
                Doing so, we notice significant evidence that compared to
                national statistics, in Georgia, 6.82 percent more complaints
                are towards credit reporting, and 3.32 percent more complaints
                are towards debt (summing auto, credit card, medical, and other
                debt) disputes (Figure 6).
              </p>
              <div className="container justify-content-center text-center">
                <div className="row justify-content-center text-center">
                  <Carousel
                    className=" justify-content-center text-center w-75"
                    variant="dark"
                  >
                    <Carousel.Item>
                      <div className="table-responsive">
                        <table class="table w-100">
                          <thead>
                            <tr class="tableizer-firstrow">
                              <th>
                                Figure 5: Product Comparison Between Georgia and
                                All Other States
                              </th>
                              <th>Georgia</th>
                              <th>All Other States</th>
                              <th>% Difference</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="table-success">
                              <td>
                                Credit reporting, credit repair services, or
                                other personal consumer reports
                              </td>
                              <td>68.39%</td>
                              <td>61.62%</td>
                              <td className="table-success">6.77%</td>
                            </tr>
                            <tr className="table-success">
                              <td>Debt collection</td>
                              <td>17.23%</td>
                              <td>13.92%</td>
                              <td className="table-success">3.31%</td>
                            </tr>
                            <tr>
                              <td>Checking or savings account</td>
                              <td>3.65%</td>
                              <td>6.63%</td>
                              <td>-2.98%</td>
                            </tr>
                            <tr>
                              <td>Mortgage</td>
                              <td>3.59%</td>
                              <td>6.12%</td>
                              <td>-2.54%</td>
                            </tr>
                            <tr>
                              <td>Credit card or prepaid card</td>
                              <td>3.38%</td>
                              <td>5.46%</td>
                              <td>-2.09%</td>
                            </tr>
                            <tr>
                              <td>
                                Money transfer, virtual currency, or money
                                service
                              </td>
                              <td>1.35%</td>
                              <td>2.90%</td>
                              <td>-1.55%</td>
                            </tr>
                            <tr>
                              <td>Vehicle loan or lease</td>
                              <td>1.18%</td>
                              <td>1.60%</td>
                              <td>-0.43%</td>
                            </tr>
                            <tr>
                              <td>Payday loan, title loan, or personal loan</td>
                              <td>0.72%</td>
                              <td>0.89%</td>
                              <td>-0.16%</td>
                            </tr>
                            <tr>
                              <td>Student loan</td>
                              <td>0.51%</td>
                              <td>0.85%</td>
                              <td>-0.34%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="table-responsive">
                        <table class="table">
                          <thead>
                            <tr class="tableizer-firstrow">
                              <th>Figure 6: Top 5 Sub-Product % Differences</th>
                              <th>Georgia</th>
                              <th>All Other States</th>
                              <th>% Difference</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Credit reporting</td>
                              <td>60.99%</td>
                              <td>67.81%</td>
                              <td>6.82%</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Credit card debt</td>
                              <td>3.76%</td>
                              <td>4.99%</td>
                              <td>1.23%</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Medical debt</td>
                              <td>2.04%</td>
                              <td>3.10%</td>
                              <td>1.07%</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Other debt</td>
                              <td>3.43%</td>
                              <td>4.03%</td>
                              <td>0.60%</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Auto debt</td>
                              <td>0.64%</td>
                              <td>1.07%</td>
                              <td>0.42%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              <p>
                For each sub-product consumers are allowed to list any
                "sub-issues" that they might have. When diving deeper into all
                sub-isssues linked to "credit reporting", there were 7 that were
                statistically significant (significant in the sense that these
                issues appeared more frequently than other sub-issues). They
                were, in no specific order,
                <ul>
                  <li>Account status incorrect</li>
                  <li>
                    Credit inquiries on your report that you don't recognize
                  </li>
                  <li>
                    Difficulty submitting a dispute or getting information about
                    a dispute over the phone
                  </li>
                  <li>Information belongs to someone else</li>
                  <li>Investigation took more than 30 days</li>
                  <li>Reporting company used your report improperly</li>
                  <li>Was not notified of investigation status or results</li>
                </ul>
                With the caveat that some complaints may not be entirely
                truthful, these seven sub-issues suggest that credit reporting
                companies could improve investigations in Georgia by
                communicating more often - especially when the customer believes
                there is a mistake. Granted this may not be entirely feasible as
                if there is a criminal element at play, then the company may
                face legal obstacles that prohibit them from communicating more.
                It thus falls on the responsibility of these companies to review
                the complaints they receive of this nature and make appropriate
                (if any) changes.
              </p>
              <p>
                When looking at all the sub-issues for the previously mentioned
                debt types three sub-issues associated with complaints from
                Georgia were significant when considering national statistics.
                16.95 percent more complaints listed "Debt was the result of
                identity theft," 2.54 percent fewer complaints listed "Attempted
                to collect [the] wrong amount," and 4.37 percent fewer
                complaints listed "Didn't receive enough information to verify
                debt." The first outlier is interesting and a somewhat cause for
                concern. Supposing that the complaint narratives are entirely
                true then this
                <b>
                  {" "}
                  suggests that identity theft is a greater problem in Georgia
                  than in any other state{" "}
                </b>
                . The extent to which that is true depends on the amount of
                response bias that may be present in complaints from Georgia.
                Returning to an earlier point, perhaps residents of Georgia are
                simply more likely to contact the CFPB than the general
                population. Therefore, it isn't so much that identity theft is
                an issue in Georgia than in other states but more so that
                residents are more likely to report it to the CFPB. We can
                "test" this theory by researching identity theft incidence in
                Georgia. The <b>Federal Trade Commission</b>'s (FTC) 2020 state
                rankings for{" "}
                <b>
                  identity theft reports per 100,000 population placed Georgia
                  at 7th
                </b>
                . If this ranking held true for 2021, then the ranking would
                indeed suggest that Georgia residents were more likely to report
                identity theft instances to the CFPB. Do note that I'm not
                saying that identity theft isn't a problem in Georgia - it
                clearly is - rather what I intend to say is that we would have
                expected data from the FTC on identity theft to match that of
                the CFPB. However, since it does not, then we can take that to
                mean that residents of Georgia were more likely to turn to the
                CFPB to report identity theft whereas other states would turn to
                the FTC. In short, this a real world example where how/who
                collects data can lead to varying conclusions.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Covid Insights</Accordion.Header>
            <Accordion.Body>
              <p>
                <b>Methodology: </b>Before discussing some observations, it is
                vital to address two things. The first is that the dataset
                doesn't include a column listing Covid as a reason to send a
                complaint. In other words, I had to create a function to search
                each complaint's narrative to determine if there was any mention
                of Covid. The second is that only 37 percent of all complaints
                included any consumer narrative, and of that 37 percent, only
                3.9 percent discussed Covid (holistically comprising 1.47
                percent of the dataset). In short, there is an arguably small
                amount of data for which we can conclude anything as there could
                have been some individuals who were affected financially by
                Covid but chose not to discuss it in their narrative. Thus we
                should be extra wary of any conclusions that we draw.
                Nonetheless, attempting to quantify how much Covid affected the
                U.S. warrants the use of this limited data.
              </p>
              <div className="container justify-content-center text-center mb-4">
                <div className="row justify-content-center text-center">
                  <Carousel
                    className=" justify-content-center text-center w-100"
                    variant="dark"
                  >
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Covid-1.png"
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Covid-2.png"
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="img-fluid w-100"
                        src="/project_one_images/Covid-3.png"
                        alt="Third slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className=" w-100"
                        src="/project_one_images/Covid-4.png"
                        alt="Fourth slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              <p>
                <b>Results/Analysis: </b>We begin by noting that complaints
                mentioning Covid were more frequent at the start of the year
                (Figure 1). Furthermore, there was a +19.79% difference between
                complaints listing Covid and those that did not for "Mortgage
                Products" (Figure 2). Intuitively{" "}
                <b>Covid had a significant effect on homeowners. </b>
                In an already difficult housing market assessing the long term
                effects of Covid on the market would make for an interesting
                investigation. In all likelihood it would probably make the
                market worse for the average American but I digress. Returning
                to the data at hand, Covid made it so that more{" "}
                <b>homeowners found it difficult to pay their mortgage </b>{" "}
                (Figure 3). We can see this in "realtime" from (Figure 1). In
                other words, at the start of the year, Covid was still in full
                swing, so many individuals still found themselves facing job and
                financial uncertainty. However, after the initial vaccination
                drives, there was a slow reopening and relaxation of guidelines
                resulting in fewer individuals facing job uncertainty. In turn
                resulting in fewer individuals being unable to pay their
                mortgage near the end of the year. On the other hand, we should
                note that Covid related Mortgage complaints began to increase
                after February - approximately 6 months after August 2020 when
                forbearance periods would begin to expire (Figure 4). We can
                therefore reason that complaints of this nature sought to
                dispute a loan, dispute the end of a forbearance period, or
                among other things, allege that a company had ignored/breached a
                forbearance agreement. Performing some quick frequency text
                analysis we find that the most common words (in order and
                excluding articles and prepositions) are: loan, mortgage,
                forbearance, covid, and modification. So indeed mortgage related
                complaints of this nature were directly tied to the forbearance
                periods enacted by the Federal Government or other mortgage
                services.
              </p>

              <p>
                NOTE: As already mentioned I have very limited Covid explicit
                data to work with so any more "findings" that I can make I feel
                would would be akin to making baseless assertions and refrain
                from including them (e.g. some findings used 0.002% of the
                dataset so it would be irresponsible to make any generalizing
                statements). Ideally the CFPB could have included a question
                such as "Is your complaint a consequence of Covid?" or "Was
                Covid one of the reasons behind submitting this complaint?" but
                I understand that asking such binary or yes/no questions leads
                to response bias and why the CFPB may have chosen against it.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Summary of Findings</Accordion.Header>
            <Accordion.Body>
              <p>
                After accounting for population, most complaints received by the
                CFPB in 2021 were from the South, South Atlantic census region,
                division respectively. At the state level, Georgia was found to
                be an outlier. That is to say, all states except for Georgia had
                their complaint rate fall into a range of values. Clearly this
                prompted further investigation by looking at the types of
                complaints the CFPB recieved from Georgia. By using Pivot Tables
                it was noted that a majority of complaints of Georgia could be
                described as either, wanting companies to improve customer
                relations, or needing to report instances of identity theft. In
                the former case, Georgia residents tended to report that credit
                reporting agencies did a poor job of communicating the results
                of an investigation - especially when residents believed there
                to be an error. Clearly it is on the onus of the companies that
                recieved complaints to address if there is possible room for
                improvement. In the latter case, the high incidence of identity
                theft reporting suggests that identity theft has become a
                greater problem for residents of Georgia. However, it must be
                discussed that perhaps there is a sort of response bias at play
                - maybe residents of Georgia are simply much more likely to
                report issues to the CFPB than residents of other states. If
                that is the case we should be wary of ranking/quantifying the
                extent of identity theft in Georgia as we can fall into the
                pitfall of overreporting. Thus, it is best advised to wait for
                the results of the Federal Trade Commision's report for identity
                theft incidence in 2021 before making any more claims.
              </p>
              <p>
                With the caveat that there was very little explicit mention of
                Covid, the most reported complaint was that Covid made it
                difficult for homeowners to pay off their mortgages. It would be
                of great interest to investigate the long term ramifications of
                this on the real estate market - in all likelihood the market
                probably got worse for the average American. While forbearance
                or loan agreements meant to provide Covid-relief were certainly
                helpful, complaints of this nature also argued that said
                agreements had been broken by certain lenders potentially
                causing people to lose their homes. If these complaints are not
                based on incorrect premises, then this is obviously alarming and
                prompts further investigation by the CFPB.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
