import React from "react";

export default function Home() {
  document.title = "Welcome";
  return (
    <>
      <div className="jumbotron jumbotron-fluid custom-jumbotron shadow-lg">
        <div className="container">
          <h1 className="display-5 jumbotron-text-spacing text-center">
            Hi! Welcome to my personal website
          </h1>
          <p className="lead text-center pb-5">Thank you for visiting</p>
        </div>
      </div>
      <h5 className="text-center text-black ms-4 me-4">
        Click on the "Projects" tab to view some personal projects or click on
        the "About" tab to learn a little more about me.
      </h5>
    </>
  );
}
